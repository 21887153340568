.experience__container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
}

.headline-mb{
  margin-bottom: 0.5rem;
}

.experience__container > div {
  padding: 2.4rem 2rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
  flex-basis: 30%;
}

.experience__container > div:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.experience__container > div h3 {
  text-align: left;
  margin-bottom: 1rem;
  color: var(--color-primary);
}

.experience__content {
  row-gap: 1rem;
  justify-content: center;
}

.experience__details {
  display: flex;
  gap: 1rem;
}

.experience__details-icon {
  margin-top: 6px;
  color: var(--color-primary);
}

.mt1{
  margin-top: 1rem;
}

.div-mt4{
  margin-top: 4.5rem;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
  .experience__container {
    grid-template-columns: 1fr;
  }

  .experience__container > div {
    width: 80%;
    padding: 2rem;
  }

  .experience__content {
    padding: 1rem;
  }

  .experience_tablet{
    width: unset !important;;
  }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
  .experience__container {
    gap: 0rem;
    justify-content: left;
  }


  .experience__container > div {
    width: 100%;
    padding: 1rem 1rem;
    flex-basis: unset !important;
  }

  .div-mobile{
    padding-top: 1rem;
  }

  .div-mt4{
    margin-top: 0;
  }

  .experience__container > div h3{
    margin-bottom: 0px;
    text-align: left;
  }
}
